var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vertical-layout h-100",class:[_vm.layoutClasses],attrs:{"data-col":_vm.isNavMenuHidden ? '1-column' : null}},[_c('div',{class:(((!_vm.hasNotMenu &&
        !_vm.isNavMenuHidden &&
        _vm.appsInMenu &&
        _vm.appsInMenu.length > 1) ||
        _vm.hasBackofficeMenu ||
        _vm.hasMyMenu ||
        _vm.hasMyselfMenu) &&
        !_vm.isTablet) || 
        _vm.isMobile ? '' : 'allMenu'},[_c('b-navbar',{staticClass:"header-navbar navbar navbar-shadow align-items-center fixed-top navbar-full-width",attrs:{"toggleable":false,"variant":_vm.navbarBackgroundColor}},[_vm._t("navbar",[_c('AppNavbarVerticalLayout',{attrs:{"toggle-vertical-menu-active":_vm.toggleVerticalMenuActive}})],{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"navbarBackgroundColor":_vm.navbarBackgroundColor,"navbarTypeClass":_vm.navbarTypeClass.concat( ['header-navbar navbar navbar-shadow align-items-center'] )})],2)],1),(
      !_vm.hasNotMenu &&
      !_vm.isNavMenuHidden &&
      ((_vm.appsInMenu && _vm.appsInMenu.length > 1) || _vm.hasStructure))?_c('VerticalNavMenu',{key:"appsin-menu",attrs:{"is-vertical-menu-active":_vm.isVerticalMenuActive,"toggle-vertical-menu-active":_vm.toggleVerticalMenuActive,"apps-in-menu":_vm.appsInMenu},scopedSlots:_vm._u([{key:"header",fn:function(slotProps){return [_vm._t("vertical-menu-header",null,null,slotProps)]}}],null,true)}):_vm._e(),(_vm.hasBackofficeMenu && _vm.isStaff)?_c('VerticalNavMenu',{key:"backoffice-menu-staff",attrs:{"is-vertical-menu-active":_vm.isVerticalMenuActive,"toggle-vertical-menu-active":_vm.toggleVerticalMenuActive,"apps-in-menu":_vm.backofficeItems,"title":"Backoffice","subtitle":"BO"},scopedSlots:_vm._u([{key:"header",fn:function(slotProps){return [_vm._t("vertical-menu-header",null,null,slotProps)]}}],null,true)}):_vm._e(),(_vm.hasMyselfMenu)?_c('VerticalNavMenu',{key:"myself-menu",attrs:{"is-vertical-menu-active":_vm.isVerticalMenuActive,"toggle-vertical-menu-active":_vm.toggleVerticalMenuActive,"apps-in-menu":_vm.membersSettingsInMenu,"is-backoffice":false,"title":_vm.$t('user-menu.profile-settings'),"subtitle":"ME"},scopedSlots:_vm._u([{key:"header",fn:function(slotProps){return [_vm._t("vertical-menu-header",null,null,slotProps)]}}],null,true)}):_vm._e(),_c('div',{staticClass:"sidenav-overlay",class:_vm.overlayClasses,on:{"click":function($event){_vm.isVerticalMenuActive = false}}}),_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c(_vm.layoutContentRenderer,_vm._b({key:_vm.layoutContentRendererKey,tag:"component",class:(!_vm.hasNotMenu &&
          !_vm.isNavMenuHidden &&
          _vm.appsInMenu &&
          _vm.appsInMenu.length > 1) ||
        _vm.hasBackofficeMenu ||
        _vm.hasMyMenu ||
        _vm.hasMyselfMenu
          ? ''
          : 'noVerticalMenu',scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}})],null,true)},'component',_vm.layoutContentRendererProps,false))],1),_c('footer',{staticClass:"footer footer-fixed",class:_vm.$route.path.includes('/backoffice') ||
      _vm.$route.path.includes('/myself') ||
      (!_vm.hasNotMenu && !_vm.isNavMenuHidden && _vm.appsInMenu && _vm.appsInMenu.length > 1)
        ? [_vm.footerTypeClass]
        : 'noVerticalMenu'},[_vm._t("footer",[_c('AppFooter')])],2),_vm._t("customizer")],2)}
var staticRenderFns = []

export { render, staticRenderFns }